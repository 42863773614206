import {TIKTOK_URL} from '@cohort/shared/apps/tiktok/common';
import type {TikTokKeywordInDescriptionVerificationAttemptData} from '@cohort/shared/apps/tiktok/triggers/keywordInDescription';
import {Trans} from 'react-i18next';

const TikTokKeywordInDescriptionTriggerStepCompletionTitleComponent: React.FC<{
  data: TikTokKeywordInDescriptionVerificationAttemptData;
  completionSuccess: boolean;
}> = ({data, completionSuccess}) => {
  const className = 'font-semibold text-gray-900';
  const linkRel = 'noopener noreferrer nofollow';

  const userLink = `${TIKTOK_URL}/@${data.username}`;

  if (!completionSuccess) {
    return (
      <Trans
        i18nKey="triggers.keyword-in-description.stepCompletionTitleComponent.error"
        ns="app-tiktok"
        values={{username: data.username, keyword: data.keyword}}
        components={{
          userLink: <a className={className} target="_blank" rel={linkRel} href={userLink} />,
          keyword: <span className={className} />,
        }}
      />
    );
  }

  return (
    <Trans
      i18nKey="triggers.keyword-in-description.stepCompletionTitleComponent.success"
      ns="app-tiktok"
      values={{username: data.username, keyword: data.keyword}}
      components={{
        userLink: <a className={className} target="_blank" rel={linkRel} href={userLink} />,
        postLink: <a className={className} target="_blank" rel={linkRel} href={data.shareUrl} />,
      }}
    />
  );
};

export default TikTokKeywordInDescriptionTriggerStepCompletionTitleComponent;
