import type {TriggerIntegrationSpec, TriggerIntegrationStruct} from '@cohort/shared/apps/trigger';
import {z} from 'zod';

const TikTokCommentTiktokTriggerConfigSchema = z.object({
  url: z.string().url(),
  videoId: z.string(),
});
type TikTokCommentTiktokTriggerConfig = z.infer<typeof TikTokCommentTiktokTriggerConfigSchema>;

const TikTokCommentTiktokVerificationAttemptDataSchema = z.object({
  username: z.string(),
  url: z.string().url(),
});
export type TikTokCommentTiktokVerificationAttemptData = z.infer<
  typeof TikTokCommentTiktokVerificationAttemptDataSchema
>;

export type TikTokCommentTiktokTriggerStruct = TriggerIntegrationStruct<
  'tiktok.comment-tiktok',
  TikTokCommentTiktokTriggerConfig,
  TikTokCommentTiktokVerificationAttemptData
>;
export const TikTokCommentTiktokTriggerSpec: TriggerIntegrationSpec<TikTokCommentTiktokTriggerStruct> =
  {
    id: 'tiktok.comment-tiktok' as const,
    type: 'action',
    configurableMaxAttempts: false,
    configSchema: TikTokCommentTiktokTriggerConfigSchema,
    verificationAttemptDataSchema: TikTokCommentTiktokVerificationAttemptDataSchema,
    verificationAttemptInputSchema: z.object({}),
    requiresMerchantConnection: true,
  };
