import type {TriggerIntegrationConfigComponentProps} from '@cohort/merchants/apps';
import {assertConnectionId} from '@cohort/merchants/apps';
import {useTikTokVideos} from '@cohort/merchants/apps/tiktok/actions';
import TikTokVideoSelector from '@cohort/merchants/apps/tiktok/triggers/components/TikTokVideoSelector';
import ErrorState from '@cohort/merchants/components/ErrorState';
import HighlightText from '@cohort/merchants/components/HighlightText';
import Loader from '@cohort/merchants/components/Loader';
import {useCurrentMerchant} from '@cohort/merchants/hooks/contexts/currentMerchant';
import {Fragment} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {match} from 'ts-pattern';

const TikTokLikeTiktokTriggerConfigComponent: React.FC<TriggerIntegrationConfigComponentProps> = ({
  connectionId,
  formReturn,
}) => {
  assertConnectionId(connectionId);

  const merchant = useCurrentMerchant();
  const {control} = formReturn;
  const {field: videoIdField} = useController({name: 'triggerIntegrationConfig.videoId', control});
  const {t} = useTranslation('app-tiktok', {
    keyPrefix: 'triggers.like-tiktok.configComponent',
  });

  const {
    data: videosResponse,
    isLoading: isVideosLoading,
    isFetched: isVideosFetched,
  } = useTikTokVideos(merchant.id, connectionId, undefined);

  const videos = videosResponse?.output.videos ?? [];

  return match({
    videos,
    isVideosLoading,
    isVideosFetched,
    formIdFieldValue: videoIdField.value,
  })
    .with({isVideosLoading: true}, () => <Loader size={30} color="gray" />)
    .with({videos: []}, () => <p>{t('noVideosMessage')}</p>)
    .with({isVideosFetched: true}, () => {
      const selectedVideo = videos.find(video => video.id === videoIdField.value);

      return (
        <Fragment>
          <TikTokVideoSelector
            showDefaultSelectButton={videoIdField.value === undefined}
            description={t('descriptionVideoSelect')}
            formReturn={formReturn}
            title={t('titleVideoSelect')}
            selectedVideo={selectedVideo}
            videos={videos}
          />
          <HighlightText type="info" text={t('unverifiedInfo')} />
        </Fragment>
      );
    })
    .otherwise(() => <ErrorState />);
};

export default TikTokLikeTiktokTriggerConfigComponent;
